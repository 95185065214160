<template>
  <div id="login-form">
    <b-card-title title-tag="h1" class="mb-1">
      {{ $t("pages.login.title") }} 👋
    </b-card-title>

    <b-card-text class="mb-5">
      {{ $t("pages.login.subtitle") }}
    </b-card-text>

    <validation-observer ref="loginFormRules" #default="{ invalid }">
      <b-form @submit.prevent="validateForm">
        <!-- email -->
        <b-form-group :label="$t('Email')" label-for="login-email">
          <validation-provider
            #default="{ errors }"
            :name="$t('Email')"
            vid="email"
            rules="required|email"
          >
            <b-form-input
              id="login-email"
              v-model="userEmail"
              :state="errors.length > 0 ? false : null"
              placeholder="hola@foravilarentals.com"
              autocomplete="email"
              tabindex="1"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- password -->
        <b-form-group>
          <div class="d-flex justify-content-between">
            <label for="login-password">{{ $t("Password") }}</label>
            <b-link
              tabindex="4"
              :to="{ name: 'foravila-forgot-password' }"
              class="text-right"
            >
              <small>{{ $t("pages.login.forgotPassword") }}</small>
            </b-link>
          </div>
          <validation-provider
            #default="{ errors }"
            :name="$t('Password')"
            vid="password"
            rules="required"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-input
                id="login-password"
                v-model="userPassword"
                :state="errors.length > 0 ? false : null"
                class="form-control-merge"
                :type="passwordFieldType"
                :placeholder="$t('Password')"
                autocomplete="current-password"
                tabindex="2"
              />
              <b-input-group-append is-text>
                <feather-icon
                  class="cursor-pointer"
                  :icon="passwordToggleIcon"
                  @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- submit -->
        <b-button
          class="mt-5 d-flex align-items-center justify-content-center"
          type="submit"
          variant="primary"
          block
          :disabled="invalid || loading"
          tabindex="3"
        >
          <b-spinner v-if="loading" small class="mr-2" />
          <span class="py-1">
            {{ $t("Log in") }}
          </span>
        </b-button>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BCardText,
  BCardTitle,
  BForm,
  BButton,
  BSpinner,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import { required, email } from "@foravila-validations";
import useBasicAuth from "@/auth/useBasicAuth";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import { notifyError } from "@methods";

export default {
  components: {
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      loading: false,
      userEmail: null,
      userPassword: null,
      required,
      email,
    };
  },
  computed: {
    currentLanguage() {
      return this.$i18n.locale.substring(0, 2).toLowerCase();
    },
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    emptyForm() {
      return !this.userEmail && !this.userPassword;
    },
  },
  watch: {
    currentLanguage() {
      // ! Working but improvement needed
      // TODO: we use nextTick to allow vee-validate to update the validation messages
      this.$nextTick(() => {
        this.switchValidationLocale();
      });
    },
  },
  mounted() {
    this.autologin();
  },
  methods: {
    switchValidationLocale() {
      localize(this.currentLanguage);
      if (!this.emptyForm) this.$refs.loginFormRules.validate();
    },
    login() {
      this.loading = true;

      useBasicAuth
        .login({
          email: this.userEmail,
          password: this.userPassword,
        })
        .then((response) => {
          const userData = response;
          if (userData.role === "ROLE_OWNER") {
            this.$ability.update(userData.ability);
            this.$router.replace({ name: "foravila-home" });
          } else {
            this.$router.replace({ name: "foravila-login" });
          }
        })
        .catch((error) => {
          this.$store.dispatch("app/setAppLoading", false);
          this.$refs.loginFormRules.setErrors(error);
          this.userPassword = null;
          this.loading = false;

          const errorMessage = error?.response?.data?.error || null;
          switch (errorMessage) {
            case "Invalid credentials.":
              notifyError(
                this.$t("errors.passwordError.title"),
                this.$t("errors.passwordError.text")
              );
              break;
            default:
              notifyError(
                this.$t("errors.loginError.title"),
                this.$t("errors.loginError.text")
              );
              break;
          }
        });
    },
    autologin() {
      const urlUserEmail = this.$route.query.e || null;
      const urlUserPassword = this.$route.query.p || null;

      if (urlUserEmail && urlUserPassword) {
        this.$store.dispatch("app/setAppLoading", true);
        this.userEmail = urlUserEmail;
        this.userPassword = urlUserPassword;
        this.login();
      }
    },
    validateForm() {
      this.$refs.loginFormRules.validate().then((success) => {
        if (!success) return;
        this.login();
      });
    },
  },
};
</script>
